export default () => {
    const sidebar = document.querySelector('.page-layout-2columns-left.page-products .sidebar-main');

    if (!sidebar) return;

    let isScrolling;

    const simulateClick = function (elem) {
    // Create our event (with options)
        const evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        });
        // If cancelled, don't dispatch our event
        const canceled = !elem.dispatchEvent(evt);
    };

    // Listen for scroll events
    window.addEventListener('scroll', () => {
    // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);

        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(() => {
            const el = document.querySelector('.products-grid');
            const loadMoreBtn = document.querySelector('.btn-load-more');

            if (el) {
                const reachedBottom = el.getBoundingClientRect().bottom < window.innerHeight;

                // Check if the bottom of the loaded items has been scrolled to
                if (reachedBottom) {
                    simulateClick(loadMoreBtn);
                }
            }
        }, 250);
    }, false);
};
